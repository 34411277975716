import React, {useEffect} from "react"
import {Link} from "gatsby"
import { FaUser, FaStoreAlt } from 'react-icons/fa'
import {useDispatch, useSelector} from "react-redux"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "../components/card/card"
import {selectCategory, onChangeByName} from "../redux/registration/registrationAction"
import {getCategories} from "../redux/api/apiAction"

const MemberPage = () => {
    const dispatch = useDispatch()
    const {categories} = useSelector(({apiReducer}) => apiReducer)

    var titre_accueil = "Merci de bien vouloir choisir votre évènement en cliquant ci-dessous";
        var txt_accueil = "<a class='sr-btn-next btn btn-sm btn-secondary' href='https://cfp2022.urevent.fr'>Accueil</a>";
        
    useEffect(() => {
        dispatch(getCategories())
    }, [])

    const onClick = (nonMember = false) => {
        dispatch(onChangeByName("unionMember", nonMember))
        dispatch(selectCategory(categories, nonMember ? nonMember : "CFP_medecin"))
    }

    return (
        <Layout>
            <SEO title="Inscription" />
                <div className={"col-10 mx-auto txt-center"}>
                    <p className={"txt-centersmall"}>Droits d'inscription incluant l'accès à l'ensemble du congrès <span className={"txt-mediumoldBlue"}><b>sur place et/ou à distance</b></span> y compris le Forum des Associations, les Journées de Psychiatrie de la Personne Âgée, la Journée Sciences Infirmières et Recherche Paramédicale, la Journée de l'ACCompagnement et de l'action médico-sociale et la Journée du CLO.</p>
                    
                </div>
                <div className={"row justify-content-around mb-3"}>	
                    <Card title={"Vous êtes :"} texte={"Médecins, pharmaciens, internes, retraités et chercheurs"} link={"/individual"} onClick={() => onClick("CFP_medecin")}/>	
                    <Card title={"Vous êtes :"} texte={"Psychologue, personnel paramédical, étudiants"} link={"/individual"} onClick={() => onClick("CFP_para")}/>	
                </div>
                <div className={"content about mt-5"}>
                <p className={"important-infos mt-5"} dangerouslySetInnerHTML={{ __html: txt_accueil }}></p>
            </div>
        </Layout>
    )
}

export default MemberPage
