import React from "react"
import PropTypes from 'prop-types'
import {Link} from "gatsby"

import "./card.scss"

const Card = ({icon, title, texte, link, onClick}) => (
    link !== null ?
        <Link to={link} className={"sr-card sr-card-btn col-12 col-sm-6 col-md-3"} onClick={value => onClick(value)}>
            <div className={"sr-card-header"}>	
                <b>{title}</b>	
            </div>
            <div className={"sr-card-body "}>
                <p dangerouslySetInnerHTML={{ __html: texte }}></p>  
            </div>
        </Link>
    :
        <div className={"sr-card sr-card-btn"} onClick={value => onClick(value)}>
            <div className={"sr-card-header"}>
                {icon}
            </div>
            <div className={"sr-card-body "}>
                {title}
            </div>
        </div>
)

Card.propTypes = {
    title:  PropTypes.string,
    texte:  PropTypes.string,
    icon:   PropTypes.node.isRequired,
    link:   PropTypes.string,
    onClick: PropTypes.func
}

Card.defaultProps = {
    title: "",
    link: null,
    onClick: () => {},
}

export default Card
